import React from 'react';
import MonthSpinner from '../Spinner/month-spinner/MonthSpinner';
import '../Navigation/Games.css';

const MonthWheel = () => {
  return(
    <div className="Games-page">
      <div className="side-bar"></div>
      <div className="Game-Container">
        <MonthSpinner />
      </div>      
      <div className="side-bar"></div>
    </div>
  );
}
export default MonthWheel;