import React from 'react';
import {NavLink} from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return(
    <div>
    <ul className="nav-header">
      <li className="nav-pages"><NavLink to="/contact" className="nav-page">Contact</NavLink></li>
      <li className="nav-pages"><NavLink to="/spin-wheels" className="nav-page">Spin Wheel</NavLink></li>
      <li className="nav-pages"><NavLink to="/" className="nav-page">Games</NavLink></li>  
      <li className="nav-pages"><NavLink to="/" className="nav-page">Timepass Game</NavLink></li>
    </ul>
    </div>
  );
}
export default Navbar;