import React from 'react';

const User = (props) => {
  return(
    <div className="snake-user-input">
      <select onChange={props.changeLevel} className="snake-change-level">
      <option value="Easy">Easy</option>
      <option value="Medium">Medium</option>
      <option value="Expert">Expert</option>
      </select>
      <input type="text" placeholder="Enter Your Name" className="snake-user-name" maxLength="15" onChange={props.handleChange} />
      <input type="button" value="START" className="snake-start-button" onClick={props.handleSubmit} />
    </div>
  );
}

export default User;