import React from 'react';
import Navbar from './Navigation/Navbar';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Games from './Navigation/Games';

import SpinWheels from './Navigation/SpinWheels';
import NumberWheel from './Wheels/NumberWheel.js';
import TruthOrDareWheel from './Wheels/TruthOrDareWheel';
import MonthWheel from './Wheels/MonthWheel'
import Contact from './Navigation/Contact';
import Guess from './Guess/GuessLevelOne';
import SnakeBoard from './Snake/SnakeBoard';
import './App.css';

class App extends React.Component {
  render(){
    return(
      <BrowserRouter>
        <Navbar />
        <Switch>
        <Route exact path="/" component={Games} />
        <Route exact path="/spin-wheels" component={SpinWheels} />
        <Route exact path="/spin-wheels/number-spinner"  component={NumberWheel} />
        <Route exact path="/spin-wheels/truth-or-dare-spinner" component={TruthOrDareWheel} />
        <Route exact path="/spin-wheels/month-spinner" component={MonthWheel} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/guess-secret-number" component={Guess} />
        <Route exact path="/snake-game" component={SnakeBoard} />
        
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;