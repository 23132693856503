import React from 'react';
import '../Spinner.css';

class MonthSpinner extends React.Component {
  state = {
    name: "circle"
  }
  
  startRotation = () => {
    this.setState({
      name: "circle start-rotate"
    });
     setTimeout(() => {
      this.setState({name: "circle start-rotate stop-rotate"});
    }, Math.floor(Math.random() * 10000) + 1);
  }
  render() {
    return(
      <div>
        <div className="triangle-down"></div>
        <ul className={this.state.name} >
          <li className="wheel-part">
            <div className="text">Jan</div>
          </li>
          <li className="wheel-part">
            <div className="text">Feb</div>
          </li>
          <li className="wheel-part">
            <div className="text">Mar</div>
          </li>
          <li className="wheel-part">
            <div className="text">Apr</div>
          </li>
          <li className="wheel-part">
            <div className="text">May</div>
          </li>
          <li className="wheel-part">
            <div className="text">Jun</div>
          </li>
          <li className="wheel-part">
            <div className="text">Jul</div>
          </li>
          <li className="wheel-part">
            <div className="text">Aug</div>
          </li>
          <li className="wheel-part">
            <div className="text">Sep</div>
          </li>
          <li className="wheel-part">
            <div className="text">Oct</div>
          </li>
          <li className="wheel-part">
            <div className="text">Nov</div>
          </li>
          <li className="wheel-part">
            <div className="text">Dec</div>
          </li>
        </ul>
        <button onClick={this.startRotation} className="spin-button" >SPIN</button>
      </div>
    );
  }
}
export default MonthSpinner;