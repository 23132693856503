import React from 'react';
import { NavLink } from 'react-router-dom';
import NumberSpinner from './NumberSpinner.PNG';
import TruthOrDare from './TruthOrDare.PNG';
import MonthSpinner from './MonthSpinner.PNG';
import './Games.css';

const SpinWheels = () => {
  return(
    <div className="Games-page">
      <div className="side-bar"></div>
      <div className="Games-Container">

        <div className="game-icon">
          <div className="game-name">
            <h3>Number Spinner</h3>
          </div>
        <div className="game-image">
         <NavLink to="/spin-wheels/number-spinner">
            <img src={NumberSpinner} alt="number spinner" />
         </NavLink>      
        </div>      
        </div>

        <div className="game-icon">
          <div className="game-name">
            <h3>Truth or Dare Spinner</h3>
          </div>
        <div className="game-image">
         <NavLink to="/spin-wheels/truth-or-dare-spinner">
            <img src={TruthOrDare} alt="truth or dare" />
         </NavLink>      
        </div>      
        </div>  

        <div className="game-icon">
          <div className="game-name">
            <h3>Month Spinner</h3>
          </div>
        <div className="game-image">
         <NavLink to="/spin-wheels/month-spinner">
            <img src={MonthSpinner} alt="Month Spinner" />
         </NavLink>      
        </div>      
        </div>

      </div>
      
      <div className="side-bar"></div>
    </div>
  );
}
export default SpinWheels;