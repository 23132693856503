import React from "react";
import "../Guess/Guess.css";

const Result = props => {
  let toprankers = props.toprankers.map((topranker,index) => {
    return(
      <table key={index}>
        <tbody>
          <tr>
            <td><p className="top-rankers">{topranker.userName}</p></td>
            <td><p className="top-rankers">{index + 1}</p></td>
            <td><p className="top-rankers">{topranker.score} - {topranker.level}</p></td>
          </tr>
        </tbody>
      </table>
    );
  })

  return (
    <div className="snake-newgame">
    <div className="new-game">
      <div>
        {
          props.showToppers === true ? (
            <div className="top-rankers-container">
              <table>
                <tbody className="top-rankers-table">
                  <tr>
                    <th className="top-rankers-heading">Name</th>
                    <th className="top-rankers-heading">Rank</th>
                    <th className="top-rankers-heading">Score</th>
                  </tr>
                </tbody>
              </table>
              {toprankers}
            </div>
          ) : null
        }
        <h2 className="answer">Answer :  </h2>
        <h5>
          Your Score: <span className="score">{props.score}</span>
        </h5>
        <h5>Click on Toppers for Rank</h5>
        
        <button className="newgame-btn" onClick={props.newgame} >New Game</button>
      </div>
      <div className="ad-space">Show Ads</div>
      <div />
    </div>
    </div>
  );
};
export default Result;
