import React from 'react';
import { NavLink } from 'react-router-dom';
import Guess from './Guess.PNG';
import Snake from './snake.png';
import './Games.css';

const Games = () => {
  return(
    <div className="Games-page">
      <div className="side-bar"></div>
      <div className="Games-Container">
        <div className="game-icon">
          <div className="game-name">
            <h3>Guess the Secret Number</h3>
          </div>
          <div className="game-image">
            <NavLink to="/guess-secret-number">
            <img src={Guess} alt="secret number" />
            </NavLink>
          </div>
        </div>    

        <div className="game-icon">
          <div className="game-name">
            <h3>Snake Game</h3>
          </div>
        <div className="game-image">
         <NavLink to="/snake-game">
            <img src={Snake} alt="snake game" />
         </NavLink>      
        </div>      
        </div>

      </div>
      
      <div className="side-bar"></div>
    </div>
  );
}
export default Games;