import React from 'react';
import Spinner from '../Spinner/Spinner';
import '../Navigation/Games.css';

const NumberWheel = () => {
  return(
    <div className="Games-page">
      <div className="side-bar"></div>
      <div className="Game-Container">
        <Spinner />
      </div>      
      <div className="side-bar"></div>
    </div>
  );
}
export default NumberWheel;