import React from 'react';
import './Guess.css';

const NextLevel = (props) => {
  return(
    <div className="new-game" >
          <div>
            <h2 className="answer">Answer : {props.Answer} </h2>
            <h3 className="congrats">Congratulations !! You Guessed the correct one </h3>
            <h5>Your Score: <span className="score">{props.score}</span> </h5>
            <button onClick={props.newgame} className="next-btn" >Continue to Level {props.level}</button>
          </div>
          <div className="ad-space">Show Ads</div>
    </div>
  );
}
export default NextLevel;